<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <label class="c-search-form__title" for="search">{{msg.search_category}}</label>
                <multiselect class="multiselect-light" :options="categories" track-by="name" label="lang" v-model="selectedCategory" :searchable="false" :close-on-select="true" :show-labels="false">
                     <template slot="singleLabel" slot-scope="{ option }">{{ option.lang }}</template>
                </multiselect>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div v-for="agency in paginatedAgencies" :key="agency.id" class="c-tourist-board__card">
                    <p class="c-tourist-board__card-title">{{agency.meta.name}}</p>
                    <dl class="c-tourist-board__list">
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_adress}} </dt>
	                        <dd class="c-tourist-board__list-item-description">
		                        {{agency.meta.address}}, {{agency.meta.destination}}
	                        </dd>
                        </div>
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_phone}} </dt><dd class="c-tourist-board__list-item-description"><a :href="'telto:' + agency.meta.phone">{{agency.meta.phone}}</a></dd>
                        </div>
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_email}} </dt><dd class="c-tourist-board__list-item-description"><a :href="'mailto:' + agency.meta.email">{{agency.meta.email}}</a></dd>
                        </div>
                        <div class="c-tourist-board__list-item-wrap">
                            <dt class="c-tourist-board__list-item-title">{{msg.block_label_website}} </dt><dd class="c-tourist-board__list-item-description"><a :href="agency.meta.web | urlChecker" target="_blank">{{agency.meta.web}}</a></dd>
                        </div>
                    </dl>
<!--	                STYLE THIS-->
	                <span v-if="agency.meta.agency_categ">
						{{ agency.meta.agency_categ }}
	                </span>
<!--	                STYLE THIS END-->
                </div>
                <div class="col-md-12 text-center">
                    <a v-if="queriedAgencies.length > articleCount"  href="#" @click.prevent="firstView = false; articleCount += 5;" class="btn btn--load">
                        {{msg.btn_load_more}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Multiselect from 'vue-multiselect';
import { mapState } from "vuex";

    export default {
        props: {
            agencies: Array
		},
        data() {
            return {
                searchFilter: "",
                categoryFilter: "",
                selectedCategory: {},
                firstView: true,
                articleCount: 5,
                categories: [],
            }
        },
        components: {
            VueBootstrapTypeahead,
            Multiselect
        },
        created () {
            //get all categories available from list of agencies, and push them to array
            const namesSet = new Set(this.agencies.map(item => item.meta.agency_categ));
            //add msg keys to categories, test by string/name since id is unavailable
	        const catDefault = {};
	        catDefault.name = "Select category";
	        catDefault.lang = "Select category";
	        this.categories.push(catDefault);

            namesSet.forEach(name => {
                const catobj = {};
                catobj.name = name;
	            const categories = {
		            "Charter": "Charter",
		            "DMC": "DMC",
		            "Catering": "Catering",
		            "Tehnika i produkcija": "Tehnika i produkcija",
		            "Udruga vodiča": "Udruga vodiča"
	            };

	            if (categories[name]) {
		            catobj.lang = categories[name];
	            }

            this.categories.push(catobj);
            //set the default category
            this.selectedCategory = this.categories[0];

            });
        },
        computed: {
            locations() {
                return [...new Set(this.agencies.map(item => item.meta.destination))];
            },
	        queriedAgencies() {
		        if (
			        (this.selectedCategory && this.selectedCategory.name !== "Select category") ||
			        this.searchFilter !== ""
		        ) {
			        let agencies = this.agencies.filter((item) => {
				        return (
					        (!this.selectedCategory || item.meta.agency_categ == this.selectedCategory.name) &&
					        item.meta.destination.toLowerCase().match(this.searchFilter.toLowerCase())
				        );
			        });
			        return agencies;
		        } else if (this.searchFilter !== "") {
			        let agencies = this.agencies.filter((item) => {
				        return item.meta.destination.toLowerCase().match(this.searchFilter.toLowerCase());
			        });
			        return agencies;
		        } else {
			        return this.agencies;
		        }
	        },
	        paginatedAgencies() {
                return this.queriedAgencies.slice(0, this.articleCount);
            },
            ...mapState({
                msg: state => state.msg
            }),
        },
        methods: {
            resetArticleCount() {
                if (this.searchFilter.length < 2) {
                    this.articleCount = 5;
                }
            }
        }
    }
</script>

<style lang="scss">
    .vbt-autcomplete-list {
        background: #FFF;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        height: auto;
        position: relative;
        margin-top: -40px;
        border: solid 1px #ebebeb;
        padding-top: 0 !important;


    }

    .vbst-item {
        color: #1d1d1b;
        font-size: 16px;
        padding: 20px;

        &:hover {
            text-decoration: none;
            color: #ebebeb;
        }
    }

    //temp multiselect styling

.multiselect {
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(172, 172, 172, 0.5);
    background-color: #1d1d1b;
    border: none;
    margin-bottom: 40px;
    font-size: 16px;
    color: #FFF;
    padding: 16px 20px;

    &--active {
        // .multiselect__select {
        // 	transform: rotate(180deg);
        // }
    }

    &__content {
    background-color: #fff;
    margin: 0;
    // min-width: 172px;
    overflow-y: auto;
    position: absolute;
    left: 15px;
    right: 0;
    width: calc(100% - 30px);
    top: 70%;
    z-index: 10;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    padding-left: 0;
    list-style-type: none;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

    &__element{
    background-color: #fff;
    margin: 0;
    min-width: 172px;
    overflow-y: auto;
    // position: absolute;
    // left: 0;
    // right: 0;
    // width: 100%;
    top: calc(100% + 20px);
    z-index: 10;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    padding-left: 0;
    list-style-type: none;
    // -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

    &__select {
    position: relative;

        &:before {
            position: absolute;
            right: 0;
            top: 65%;
            color: #FFF;
            margin-top: 10px;
            border-style: solid;
            border-width: 6px 6px 0;
            border-color: #FFF transparent transparent;
            content: "";
        }
    }

    &__option {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.28571em;
    font-weight: 500;
    color: #212529;
    padding: 20px;
    display: block;
    cursor: pointer;
    -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

    &--highlight, --selected {
        background-color: #1d1d1b;
        color: #ebebeb;
        font-size: 16px;
        padding: 20px;
    }

}

}

.c-search-form__input {
	height: 60px;
}

</style>

