<template>
	<div>
		<div class="filters-wrap">
			<div v-if="enabledFilters.includes('searchLocation')" class="filters-wrap__item">
				<label class="c-search-form__title" for="search">{{ msg.search_location }}</label>
				<input
					:placeholder="msg.search_location"
					class="c-search-form__input c-search-form__input--light c-search-form__input--small"
					type="text"
					v-model="searchFilter"
					@input="resetArticleCount"
					name="search"
				/>
			</div>

			<div v-if="enabledFilters.includes('searchRating')" class="filters-wrap__item">
				<label class="c-search-form__title" for="search">{{ msg.search_rating }}</label>
				<multiselect
					@input="resetArticleCount"
					v-model="selectedRating"
					:options="rating"
					track-by="id"
					:searchable="false"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					class="multiselect-light js-select-job-type"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				</multiselect>
			</div>

			<div v-if="enabledFilters.includes('roomCapacitySort')" class="filters-wrap__item">
				<label class="c-search-form__title" for="search">{{ msg.sort_by_room_capacity }}</label>
				<multiselect
					@input="resetArticleCount"
					v-model="selectedRoomCapacitySort"
					:options="roomCapacitySortOptions"
					track-by="id"
					:searchable="false"
					:close-on-select="true"
					:show-labels="false"
					label="name"
					class="multiselect-light js-select-job-type"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				</multiselect>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-md-6 col-lg-12">
				<AccommodationSearchCard
					v-for="(accommodation, index) in data"
					:key="accommodation.id"
					:accommodation="accommodation"
				/>
				<div v-if="!data.length" class="col-md-12 text-center">
					<p>{{ msg.search_noresults }}</p>
				</div>

				<div class="col-md-12 text-center">
					<a
						v-if="data.length > articleCount - 1"
						href="#"
						@click.prevent="loadMore"
						class="btn btn--load"
					>{{ msg.btn_load_more }}</a>
				</div>
			</div>
		</div>
		<accommodation-modal v-if="showAccommodationModal"></accommodation-modal>
	</div>
</template>

<script>
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
	props: {
		lang: String,
		type: String,
		enabledFilters: {
			type: Array,
			default: () => ['searchLocation', 'searchRating', 'roomCapacitySort'] // Enable all filters by default
		},
	},
	created() {
		this.selectedRoomCapacitySort = this.roomCapacitySortOptions[0];
		this.selectedMeetingRoomCapacitySort = this.meetingRoomCapacitySortOptions[0];

		this.selectedRating = this.rating[0];
		this.rating[0].name = this.msg.search_select_all;
	},
	data() {
		return {
			data: [],
			searchFilter: "",
			rating: [
				{ id: "", name: "Select All" },
				{ id: 5, name: "5" },
				{ id: 4, name: "4" },
				{ id: 3, name: "3" },
			],
			roomCapacitySortOptions: [
				{ id: "asc", name: "Room Capacity: Low to High" },
				{ id: "desc", name: "Room Capacity: High to Low" },
			],
			meetingRoomCapacitySortOptions: [
				{ id: "asc", name: "Meeting Room Capacity: Low to High" },
				{ id: "desc", name: "Meeting Room Capacity: High to Low" },
			],
			selectedRoomCapacitySort: {},
			selectedMeetingRoomCapacitySort: {},
			selectedRating: {},
			articleCount: 5,
		};
	},
	components: {
		VueBootstrapTypeahead,
		Multiselect,
	},
	computed: {
		...mapState({
			showAccommodationModal: (state) => state.showAccommodationModal,
			msg: (state) => state.msg,
		}),
	},
	methods: {
		fetchItems() {
			axios
				.get(`/${this.lang}/xhotels`, {
					params: {
						location: this.searchFilter,
						rating: this.selectedRating.id,
						roomCapacitySort: this.selectedRoomCapacitySort.id,
						meetingRoomCapacitySort: this.selectedMeetingRoomCapacitySort.id,
						limit: this.articleCount,
						type: this.type,
					},
				})
				.then((response) => {
					if (response.data.success) {
						console.log("DATA BEFORE:");
						console.log(this.data);
						this.data = [];
						this.data.splice(0, this.data.length);
						console.log("DATA RESET:");
						console.log(this.data);
						this.data = response.data.data;
						console.log("DATA AFTER:");
						console.log(this.data);
					}
				})
				.catch((error) => {
					// Handle error here
				});
		},
		loadMore() {
			this.articleCount += 5;
			this.fetchItems();
		},
		resetArticleCount() {
			if (this.searchFilter.length < 2) {
				this.articleCount = 5;
			}
		},
	},
	watch: {
		searchFilter: _.debounce(function () {
			this.fetchItems();
		}, 500),
		selectedRoomCapacitySort: _.debounce(function () {
			this.fetchItems();
		}, 500),
		selectedRating: _.debounce(function () {
			this.fetchItems();
		}, 500),
	},
};
</script>


<style lang="scss">

//temp multiselect styling
.multiselect-light {
	width: 100%;
	box-shadow: inset 0 0 0 1px #DFDFDF;
	background-color: #fff;
	border: none;
	margin-bottom: 22px;
	font-size: 16px;
	color: #1D1D1B;
	padding: 18px 16px;
	position: relative;

	@media (min-width: 992px) {
		margin-bottom: 0;
	}

	&--active {

	}

	.multiselect__content {
		background-color: #fff;
		margin: 0;
		overflow-y: auto;
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: 100%;
		z-index: 16;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		padding-left: 0;
		list-style-type: none;
		-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	.multiselect__element{
		background-color: #fff;
		margin: 0;
		min-width: 144px;
		overflow-y: auto;
		top: calc(100% + 20px);
		z-index: 10;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		padding-left: 0;
		list-style-type: none;
	}

	.multiselect__select {
		position: relative;

		&:before {
			position: absolute;
			right: 0;
			top: 65%;
			color: #1D1D1B;
			margin-top: 10px;
			border-style: solid;
			border-width: 6px 6px 0;
			border-color: #1D1D1B transparent transparent;
			content: "";
		}
	}

	.multiselect__option {
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 1.28571em;
		font-weight: 400;
		color: #1D1D1B;
		background-color: #fff;
		padding: 20px;
		display: block;
		cursor: pointer;
		-webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
		transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

		&--highlight, --selected {
			background-color: #1d1d1b;
			color: #ebebeb;
			padding: 20px;
		}

	}
}


.filters-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	position: relative;
	z-index: 12;

	@media (min-width: 992px) {
		// flex-wrap: nowrap;
		gap: 30px;
		margin-bottom: 30px;
	}

	&__item {
		position: relative;
		// z-index: 2;
		flex-basis: 100%;

		@media (min-width: 992px) {
			// flex: 0 1 218px;
			flex: 0 1 calc(50% - 15px);
		}
	}

	&__item--small {
		@media (min-width: 992px) {
			flex: 0 0 144px;
		}
	}

	.c-search-form__input {
		height: 60px;

		@media (min-width: 992px) {
			margin-bottom: 0;
		}
	}

}







// table styles
$table-header: #C7D0D433;
$table-header-border: #1565C0;
$table-border: #E6E6E6;
$row-bg: #f4f2f1;


.table-container {
	display: block;
}

.flag-icon {
	margin-right: 0.1em;
}

.flex-table {
	display: flex;
	flex-flow: row wrap;
	border-left: solid 1px $table-border;
	transition: 0.5s;
	&:last-of-type {
		border-bottom: 1px solid $table-border;
	}
	&.row-group:nth-child(odd) .flex-cell {
		background: $row-bg;
	}
	&:hover {
		background: #F5F5F5;
		transition: 500ms;
	}
}

.flex-cell {
	width: 25%;
	text-align: center;
	padding: 12px 16px;
	font-family: Liszt FY;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.flex-cell--wide {
	width: 75%;
	text-align: left;
}

.flex-cell--accent {
	font-family: Kapra;
	font-size: 19px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.04em;
}

.rowspan {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: center;
}

.column {
	display: flex;
	flex-flow: column wrap;
	width: 75%;
	padding: 0;
	.flex-row {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		padding: 0;
		border: 0;
		border-bottom: solid 1px $table-border;
		&:hover {
			background: #F5F5F5;
			transition: 500ms;
		}
	}
}

/* @media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 3);

   &.first {
     width: 100%;
   }
  }

  .column {
    width: 100%;
  }
} */

/* @media all and (max-width: 430px) {

  .flex-table {
    .flex-row {
      border-bottom: 0;
    }
    .flex-row:last-of-type {
      border-bottom: solid 1px $table-border;
    }
  }

  .header {
    .flex-row {
      border-bottom: solid 1px;
    }
  }

  .flex-row {
    width: 100%;

   &.first {
     width: 100%;
     border-bottom: solid 1px $table-border;
   }
  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px $table-border;
    }
  }
} */



</style>

