import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
Vue.use(Vuex);

import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
 
Vue.use(VueSimpleAccordion, {
  // ... Options go here
});

const state = {
	msg: {},
	showAccommodationModal: false,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters: {},

});